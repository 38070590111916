<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CRow>
          <CCol sm="12">
            <CCard>
              <CCardHeader>
                <CCardTitle>Details of Part #{{ part.name }}</CCardTitle>
                <div class="card-header-actions"></div>
              </CCardHeader>

              <CCardBody>
                <CCol sm="6">
                  <CInput
                    name="name"
                    label="Name"
                    placeholder="Enter the name of the part"
                    v-model="part.name"
                  />
                </CCol>
                
                <CCol sm="6">
                  <CSelect
                    label="Category"
                    placeholder="select"
                    :options="part_categories"
                    :value.sync="part.part_category_id"
                  />
                </CCol>
                
                <CCol sm="6">
                  <CInputFile
                    name="image"
                    label="Image"
                    description="Maximum total upload size is 1MB.
                      Images can be compressed using: imagecompressor.com"
                    v-on:change="setImage"
                    required
                  />
                  <img width="50" :src="storageURL + part.image" aria-busy="" />
                </CCol>
              </CCardBody>
            </CCard>
          </CCol>

          <CCol sm="12">
            <CButton
              variant="outline"
              type="submit"
              color="primary"
              @click="updatePart"
            >
              <CIcon name="cil-check-circle" />
              Update
            </CButton>
            <CButton
              class="float-right"
              variant="outline"
              type="submit"
              color="danger"
              @click="deletePart"
            >
              <CIcon name="cil-trash" /> Delete
            </CButton>
          </CCol>
        </CRow>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import PartAPI from "/app/src/api/part.js";
import PartCategoryAPI from "/app/src/api/part-category.js";

export default {
  name: "Detail",
  data: () => {
    return {
      part: {
        name: "",
        part_category_id: null
      },
      new_image: {},
      part_categories: [],
      storageURL: process.env.VUE_APP_STORAGE_URL,
      partAPI: new PartAPI(),
      partCategoryAPI: new PartCategoryAPI(),
    };
  },
  created: function () {
    this.getPartCategories();
    this.getPart(this.$route.params.id);
  },
  methods: {
    getPartCategories: function() {
      this.$store.dispatch('loading')
      let self = this
      this.partCategoryAPI.all()
      .then((part_categories) => {
        this.$store.dispatch('stopLoading')
        for(var i = 0; i < part_categories.length; i++) {
          part_categories[i].label = part_categories[i].name;
          part_categories[i].value = part_categories[i].id;
        }
        self.part_categories = part_categories
      })
      .catch((error) => {
        this.$store.dispatch('stopLoading')
        console.log(error)
      })
    },
    getPart: function (id) {
      this.$store.dispatch("loading");
      let self = this;
      self.partAPI
        .detail(id)
        .then((part) => {
          this.$store.dispatch("stopLoading");
          self.part = part;
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          console.log(error);
          this.$alert.show({ type: "danger", message: error });
        });
    },
    validateSubmission: function () {
      if (this.part.name.length < 1) return "Name is required";
      if (this.part.part_category_id < 1) return "Part category is required";
      return true;
    },
    updatePart: function (id) {
      let message = this.validateSubmission();
      if (message !== true) {
        this.$alert.show({ type: "danger", message: message });
        return;
      }

      this.$store.dispatch("loading");
      let self = this;
      let data = {
        id: this.part.id,
        name: this.part.name,
        part_category_id: this.part.part_category_id,
        image: this.part.image,
      };
      console.log(this.new_image);
      this.partAPI
        .update(data, this.new_image)
        .then((part) => {
          self.$store.dispatch("stopLoading");
          self.$alert.show({
            type: "success",
            message: "Part has been updated",
          });
          self.part = part;
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("stopLoading");
          this.$alert.show({ type: "danger", message: error });
        });
    },
    deletePart: function (id) {
      const params = {
        title: "Confirm",
        text: "Are you sure you want to delete?",
        onConfirm: () => {
          this.$store.dispatch("loading");
          this.partAPI
            .delete(this.$route.params.id)
            .then((result) => {
              this.$store.dispatch("stopLoading");
              console.log(result);
              this.$router.push({ path: "/manager/parts" });
            })
            .catch((error) => {
              this.$store.dispatch("stopLoading");
              console.log(error);
              this.$alert.show({ type: "danger", message: error });
            });
        },
      };
      this.$confirm.show(params);
    },
    setImage: function (files, e) {
      this.new_image = files[0];
    },
    deleteImage: function () {
      this.part.image = "";
    },
  },
};
</script>

<style scoped>
.logo-img img {
  width: 100%;
}
.part-images img {
  width: 100%;
}

.delete-img-btn {
  position: absolute;
  top: -10px;
  right: 5px;
  z-index: 999;
  color: white;
  background-color: red;
  padding: 5px;
  border-radius: 50px;
  width: 25px;
  height: 25px;
  border: none;
}

.delete-img-btn svg {
  margin-top: -9px;
}
</style>
